import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';

type Logo =
    | {
          url: string;
          alt?: string | null;
      }
    | undefined
    | null;
@Component({
    selector: 'ui-m040-c015-warrenty-footer-logos',
    standalone: true,
    imports: [M000C010ImageComponent],
    templateUrl: './m040-c015-warrenty-footer-logos.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M040C015WarrentyFooterLogosComponent {
    logos = input<Logo[]>();
}
