import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import { ImageAiFocus } from '../../000-primitives/m000-c010-image/m000-c010-image.interface';
import { M040C010EnvkvComponent } from '../../040-content-structure/m040-c010-envkv/m040-c010-envkv.component';
import { M040C030BadgeComponent } from '../../040-content-structure/m040-c030-badge/m040-c030-badge.component';

@Component({
    selector: 'ui-m030-c110-model-hero',
    standalone: true,
    templateUrl: './m030-c110-model-hero.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        M000C010ImageComponent,
        M040C030BadgeComponent,
        RouterLinkDirective,
        M040C010EnvkvComponent,
        NgTemplateOutlet,
    ],
})
export class M030C110ModelHeroComponent {
    title = input.required<string>();
    text = input.required<string>();
    image = input.required<{ url: string; alt?: string | null }>();
    logoImages = input<Array<{ url: string; alt?: string | null }>>();
    isAvailable = input(false, { transform: booleanAttribute });
    isDigital = input(false, { transform: booleanAttribute });
    isFirstView = input.required({ transform: booleanAttribute });
    envkv = input<{ model: string; subheadline?: string; envkv: ApiTypes.Envkv; score: string }>();

    ImageAiFocus = ImageAiFocus;
}
