<div class="flex flex-col bg-grey-100 md:grid md:grid-cols-2 xl:flex xl:flex-row xl:items-center">
    <div class="relative h-full flex-grow xl:w-1/2">
        <ui-m000-c010-image
            class="block h-full"
            [classList]="
                'h-full w-full aspect-[766/560] md:aspect-square xl:aspect-[760/600] 2xl:aspect-[1000/600] object-cover'
            "
            [image]="heroImage()"
            [priority]="true"
            [alt]="imageAlt()"
            cover
        />
        <ng-content select="[imageEdit]" />
    </div>
    <div class="flex flex-col items-start justify-center p-24 text-black md:p-56 xl:w-1/2 xl:pr-[112px]">
        <div class="mb-8 text-31 leading-36 empty:hidden md:mb-4 md:text-34 md:leading-41">
            <ng-content select="[headline]" />
        </div>

        <div class="mt-4 text-22 leading-28 empty:hidden">
            <ng-content select="[subheadline]" />
        </div>

        <div class="w-full whitespace-pre-line">
            @if (strongText()) {
                <p class="font-headline text-16 font-medium leading-27">{{ strongText() }}</p>
            }
            <div class="text-16 leading-27 empty:hidden">
                <ng-content select="[text]" />
            </div>
            @if (list()) {
                <div class="mt-12 flex flex-col gap-12">
                    @for (item of list(); track $index) {
                        <div class="flex items-center gap-12">
                            <i class="text-[40px]" [ngClass]="[item.icon]"></i>
                            @let itemText = item.text | footnotes;
                            <p class="break-words text-16 leading-27" [innerHTML]="itemText()"></p>
                        </div>
                    }
                </div>
            }

            <div class="mt-24 flex flex-col gap-20 empty:hidden">
                <ng-content select="[cta]" />
            </div>
        </div>
    </div>
</div>
