import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input, output } from '@angular/core';
import { FootnotesPipe, RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M010C010IconDoubleCtaComponent } from '../../010-buttons-and-links/m010-c010-icon-double-cta/m010-c010-icon-double-cta.component';
import { M010C020IconLinkComponent } from '../../010-buttons-and-links/m010-c020-icon-link/m010-c020-icon-link.component';

@Component({
    selector: 'ui-m040-c045-offer-banner',
    standalone: true,
    templateUrl: './m040-c045-offer-banner.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        M010C010IconDoubleCtaComponent,
        M010C020IconLinkComponent,
        RouterLinkDirective,
        NgTemplateOutlet,
        FootnotesPipe,
    ],
})
export class M040C045OfferBannerComponent {
    data = input<Array<{ label: string; value: string; unit: string; link?: string; linkText?: string }>>();

    ctaLink = input.required<string>();
    ctaText = input.required<string>();
    ctaSubText = input.required<string>();

    link = input.required<string>();
    linkText = input.required<string>();

    primaryButton = input.required<string>();
    primaryButtonText = input.required<string>();
    showOnlyPrimaryButton = input(false, { transform: booleanAttribute });

    primaryButtonClicked = output();
    ctaClicked = output();
    linkClicked = output();
}
