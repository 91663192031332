<div class="relative bg-grey-500">
    <div
        class="inner-page-wrapper flex w-full flex-col gap-x-56 gap-y-24 py-40 md:flex-row md:items-center md:justify-between md:p-24"
    >
        <div class="absolute left-0 right-0 top-0 z-[1] block h-[120px] w-full bg-white md:hidden"></div>
        <div class="relative z-10 flex w-full flex-col gap-x-56 gap-y-24 md:flex-row md:items-center">
            <ui-m040-c070-contact-person
                class="block w-full max-w-[262px]"
                [data]="contact()"
                [isFirstView]="isFirstView()"
                showPhone="false"
                showMail="false"
            />
            <ng-content />
        </div>
        <div class="flex md:flex-shrink-0 md:flex-grow md:justify-end">
            <a class="group button-primary w-full md:w-auto" [hyundaiRouterLink]="ctaLink()">
                <i class="icon-phone mr-8 text-24 text-white group-hover:animate-wiggle"></i>
                {{ cta() }}
            </a>
        </div>
    </div>
</div>
