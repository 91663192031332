<div
    class="mx-auto flex max-w-[1264px] flex-col md:grid md:grid-cols-2 md:px-24 md:pb-[80px] md:pt-40 lg:flex lg:items-center"
    [ngClass]="{
        'lg:flex-row-reverse': !imageLeft(),
        'lg:flex-row': imageLeft(),
    }"
>
    <div class="relative h-full flex-grow">
        <ui-m000-c010-image
            class="relative block h-full"
            [classList]="
                'h-full w-full aspect-[766/432] md:aspect-[4/3] lg:aspect-[686/385] ' + imageContains()
                    ? 'object-contain'
                    : 'object-cover'
            "
            [image]="{
                image: image(),
                desktop: { width: 686, height: 385 },
                tablet: { width: 608, height: 456 },
                mobile: { width: 766, height: 432 },
            }"
            [priority]="isFirstView()"
            [alt]="imageAlt() ?? 'Default Teaser Image'"
            [cover]="!imageContains()"
            [contain]="imageContains()"
        />
        <ng-content select="[imageEdit]" />
    </div>
    <div
        class="flex flex-col items-start justify-center px-24 pb-40 pt-24 lg:max-w-[530px] lg:pb-24"
        [ngClass]="{
            'lg:pl-0 lg:pr-56': !imageLeft(),
            'lg:pl-56 lg:pr-0': imageLeft(),
        }"
    >
        <div
            class="mb-12 font-headline text-24 font-medium leading-28 text-black empty:hidden md:text-34 md:leading-41"
        >
            <ng-content select="[headline]" />
        </div>

        <div class="mb-12 font-headline text-16 font-medium leading-27 text-black empty:hidden lg:hidden">
            <ng-content select="[mobileSubheadline]" />
        </div>

        <div class="w-full">
            @if (list()) {
                <div class="mt-12 flex flex-col gap-12">
                    @for (item of list(); track $index) {
                        <div class="flex items-center gap-12">
                            <i class="text-40 text-black" [ngClass]="[item.icon]"></i>
                            <p class="text-16 leading-27 text-black">{{ item.text }}</p>
                        </div>
                    }
                </div>
            }

            <p class="whitespace-pre-line text-16 leading-27 text-black empty:hidden">
                <ng-content select="[text]" />
            </p>

            <div class="mt-24 inline-flex flex-col gap-20 empty:hidden">
                <ng-content select="[cta]" />
            </div>
        </div>
    </div>
</div>
