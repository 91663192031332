import { booleanAttribute, ChangeDetectionStrategy, Component, input, output, ViewEncapsulation } from '@angular/core';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M010C010IconDoubleCtaComponent } from '../../010-buttons-and-links/m010-c010-icon-double-cta/m010-c010-icon-double-cta.component';
import { M010C020IconLinkComponent } from '../../010-buttons-and-links/m010-c020-icon-link/m010-c020-icon-link.component';

@Component({
    selector: 'ui-m040-c120-contact-model-banner',
    standalone: true,
    templateUrl: './m040-c120-contact-model-banner.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLinkDirective, M010C010IconDoubleCtaComponent, M010C020IconLinkComponent],
})
export class M040C130ContactModelBannerComponent {
    ctaLink = input.required<string>();
    ctaText = input.required<string>();
    ctaSubText = input.required<string>();

    link = input.required<string>();
    linkText = input.required<string>();

    primaryButton = input.required<string>();
    primaryButtonText = input.required<string>();
    showOnlyPrimaryButton = input(false, { transform: booleanAttribute });

    primaryButtonClicked = output();
    ctaClicked = output();
    linkClicked = output();
}
