import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Renderer2, ViewEncapsulation, inject, input } from '@angular/core';
import { HUBSPOT_FORM_CONFIG, injectScript } from '@hyundai/ng-common-lib';

declare const hbspt: {
    forms: {
        create: (options: {
            region: 'eu1';
            portalId: string;
            formId: string;
            target: string;
            onFormReady: (form: HTMLElement) => void;
        }) => void;
    };
};

@Component({
    selector: 'ui-hubspot-form',
    standalone: true,
    imports: [],
    templateUrl: './hubspot-form.component.html',
    styleUrl: './hubspot-form.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HubspotFormComponent {
    protected readonly hubspotFormConfig = inject(HUBSPOT_FORM_CONFIG);
    protected readonly renderer = inject(Renderer2);
    protected readonly document = inject(DOCUMENT);

    auth0FieldID = input('auth0id');
    formId = input.required<string>();
    userId = input.required<string>();

    constructor() {
        injectScript('//js-eu1.hsforms.net/forms/embed/v2.js').then(() => {
            hbspt.forms.create({
                region: this.hubspotFormConfig.region,
                portalId: this.hubspotFormConfig.portalId,
                formId: this.formId(),
                target: '#formContainer',
                onFormReady: (form) => {
                    const auth0Field = form.querySelector<HTMLInputElement>(`input[name="${this.auth0FieldID()}"]`);
                    if (!auth0Field) {
                        return;
                    }
                    auth0Field.value = this.userId();
                },
            });
        });
    }
}
