<a
    class="group flex h-full gap-12 border-b-1 border-grey-200 px-16 pb-8 pt-16 md:px-0 md:pt-0"
    [hyundaiRouterLink]="link()"
    [trackingElTarget]="link()"
    trackingElLabel="Service Request"
    trackingEvent="engagement_click"
    tracking
    trackingElType="teaser"
>
    @if (icon(); as i) {
        <i class="text-40 text-black" [ngClass]="[i]"></i>
    }
    <div class="flex w-full flex-col items-start justify-between">
        <div>
            @let headlineFootnote = headline() | footnotes;
            @let textFootnote = text() | footnotes;
            <h3 class="text-16 leading-27 text-black" [innerHTML]="headlineFootnote()"></h3>
            <div class="flex items-center justify-between gap-8">
                <p class="leading-21 text-14 text-black" [innerHTML]="textFootnote()"></p>

                @if (!priceTags() && link()) {
                    <i
                        class="icon-chevron-right mr-12 size-24 text-24 text-accent group-hover:animate-bouncingLeft"
                    ></i>
                }
            </div>
        </div>
        @if (priceTags(); as tags) {
            <div class="mt-12 flex w-full items-center justify-between gap-8">
                <div class="flex gap-16">
                    @for (item of tags; track $index) {
                        <div>
                            @if (item.headline) {
                                <p class="-mb-6 text-16 leading-27 text-black">{{ item.headline }}</p>
                            }
                            @let itemFootnote = item.text | footnotes;
                            <p
                                class="font-headline text-16 font-medium leading-27 text-black"
                                [innerHTML]="itemFootnote()"
                            ></p>
                        </div>
                    }
                </div>
                @if (link()) {
                    <i
                        class="icon-chevron-right mr-12 size-24 text-24 text-accent group-hover:animate-bouncingLeft"
                    ></i>
                }
            </div>
        }
    </div>
</a>
