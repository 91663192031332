import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    booleanAttribute,
    computed,
    input,
    output,
    signal,
} from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { M060C010VehicleShowroomButtonsComponent } from './m060-c010-vehicle-showroom-buttons/m060-c010-vehicle-showroom-buttons.component';
import { M060C010VehicleShowroomHeaderComponent } from './m060-c010-vehicle-showroom-header/m060-c010-vehicle-showroom-header.component';
import { M060C010VehicleShowroomItemComponent } from './m060-c010-vehicle-showroom-item/m060-c010-vehicle-showroom-item.component';
import { M060C010VehicleShowroomSliderComponent } from './m060-c010-vehicle-showroom-slider/m060-c010-vehicle-showroom-slider.component';
import { M060C010VehicleShowroomTabsComponent } from './m060-c010-vehicle-showroom-tabs/m060-c010-vehicle-showroom-tabs.component';
import type { M060C010VehicleShowroom } from './m060-c010-vehicle-showroom.interface';

@Component({
    selector: 'ui-m060-c010-vehicle-showroom-wrapper',
    standalone: true,
    templateUrl: './m060-c010-vehicle-showroom-wrapper.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        M060C010VehicleShowroomHeaderComponent,
        M060C010VehicleShowroomSliderComponent,
        M060C010VehicleShowroomButtonsComponent,
        M060C010VehicleShowroomItemComponent,
        M060C010VehicleShowroomTabsComponent,
    ],
    host: {
        class: 'relative',
    },
})
export class M060C010VehicleShowroomWrapperComponent {
    headline = input.required<string>();
    subheadline = input.required<string>();
    cars = input.required<M060C010VehicleShowroom[]>();
    ctaLink = input.required<string>();
    ctaText = input.required<string>();
    ctaSubText = input.required<string>();
    link = input.required<string>();
    linkText = input.required<string>();
    primaryButton = input.required<string>();
    primaryButtonText = input.required<string>();
    envkv = input<{ model: string; subheadline?: string; envkv: ApiTypes.Envkv; score: string }>();
    isFirstView = input.required({ transform: booleanAttribute });

    currentTabIndex = signal<number>(0);
    showOnlyPrimaryButton = input(false, { transform: booleanAttribute });

    emitCurrentTabIndex = output<number>();
    primaryButtonClicked = output();
    ctaClicked = output();
    linkClicked = output();

    tabs = computed(() => {
        const cars = this.cars();
        return cars.map((car) => car.label);
    });

    changeCurrentTabIndex = (index: number) => {
        this.currentTabIndex.set(index);
        this.emitCurrentTabIndex.emit(index);
    };
}
