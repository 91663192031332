<div class="mb-8 flex justify-start gap-40 overflow-auto lg:mb-24 lg:justify-center" role="tablist">
    @for (category of categories(); track $index) {
        <button
            class="border-b-4 font-headline text-14 leading-27 text-accent hover:font-medium md:text-18"
            [ngClass]="{
                'border-b-accent font-medium': category === activeCategory(),
                'border-b-transparent': category !== activeCategory(),
            }"
            [attr.aria-selected]="category === activeCategory()"
            (click)="categorySelected.emit(category)"
            role="tab"
        >
            {{ category }}
        </button>
    }
</div>
<div class="grid grid-cols-2 place-items-center gap-16 md:grid-cols-3 lg:gap-56 xl:grid-cols-4">
    @for (model of filteredModels(); track model.slug) {
        <a
            class="group relative flex h-full flex-col gap-8 bg-white px-8 pb-4 pt-32 font-headline lg:w-[270px] lg:px-24 lg:pb-16"
            [hyundaiRouterLink]="model.detailUrl"
            blockTriggerPreloading
        >
            <div class="absolute left-8 top-8 flex items-center gap-8 lg:left-24 lg:top-12">
                @if (model.campaigns) {
                    <p
                        class="flex h-[16px] items-center justify-center bg-accent px-12 text-10 font-medium leading-27 text-white lg:h-[22px] lg:text-16"
                    >
                        AKTION
                    </p>
                }
            </div>
            <ui-m000-c010-image
                class="transition-all group-hover:scale-110"
                [image]="{
                    image: model.image.url,
                    desktop: { width: 222, height: 130 },
                    tablet: { width: 222, height: 130 },
                    mobile: { width: 336, height: 192 },
                }"
                [alt]="model.image.alt ?? model.name"
                [priority]="isFirstView()"
                cover
            />
            <div class="leading-21 flex flex-col">
                <span class="text-14 font-medium text-black lg:text-18">
                    {{ model.alternativerModelListName ?? model.name }}
                </span>
                <span class="text-12 leading-20 text-black lg:text-16 lg:leading-27">Bei uns bestellbar</span>
            </div>
        </a>
    }
</div>
