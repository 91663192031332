@if (data().envkv && data(); as d) {
    <div class="relative flex flex-row items-start gap-8 lg:gap-16">
        <div class="relative grid w-full grid-cols-3 gap-x-24 gap-y-12 lg:w-max lg:place-items-center lg:gap-x-48">
            @if (d.envkv && d.envkv.__typename === EnvkvType.EnVkvElektro) {
                <ng-container
                    [ngTemplateOutlet]="kwhPer100KmCombined"
                    [ngTemplateOutletContext]="{ value: d.envkv.consumptionCombined }"
                />

                <ng-container
                    [ngTemplateOutlet]="gPerKmCombined"
                    [ngTemplateOutletContext]="{ value: d.envkv.co2Combined }"
                />

                <ng-container [ngTemplateOutlet]="score" />
            } @else if (d.envkv && d.envkv.__typename === EnvkvType.EnVkvPlugInHybrid) {
                <ng-container
                    [ngTemplateOutlet]="lPer100kmSummedCombined"
                    [ngTemplateOutletContext]="{ value: d.envkv.consumptionCombinedLitres }"
                />
                <ng-container
                    [ngTemplateOutlet]="lPer100kmElectricCombined"
                    [ngTemplateOutletContext]="{ value: d.envkv.consumptionCombinedDischargedBattery }"
                />
                <ng-container
                    [ngTemplateOutlet]="kwhPer100kmCombined"
                    [ngTemplateOutletContext]="{ value: d.envkv.consumptionCombinedElectric }"
                />
                <ng-container
                    [ngTemplateOutlet]="gPerKmSummedCombined"
                    [ngTemplateOutletContext]="{ value: d.envkv.co2Combined }"
                />
                <ng-container [ngTemplateOutlet]="co2ClassCombined" />
                <ng-container
                    [ngTemplateOutlet]="co2ClassElectricCombined"
                    [ngTemplateOutletContext]="{ value: d.envkv.co2ClassDischargedBattery }"
                />
            } @else if (d.envkv && d.envkv.__typename === EnvkvType.EnVkvVerbrennerHybrid) {
                <ng-container
                    [ngTemplateOutlet]="lPer100kmCombined"
                    [ngTemplateOutletContext]="{ value: d.envkv.consumptionCombined }"
                />

                <ng-container
                    [ngTemplateOutlet]="gPerKmCombined"
                    [ngTemplateOutletContext]="{ value: d.envkv.co2Combined }"
                />

                <ng-container [ngTemplateOutlet]="score" />
            }
        </div>

        <button
            class="absolute -top-6 right-0 lg:relative lg:right-[initial] lg:top-[initial] lg:self-center"
            (click)="openGallery()"
            title="Envkv Gallerie öffnen"
        >
            <i class="icon-info text-16 {{ textColor() }}"></i>
        </button>
    </div>

    <ng-template #score>
        <div class="flex w-max items-center gap-4">
            <i class="icon-envkv-eco-plant text-24 {{ textColor() }}"></i>
            <span class="font-text text-10 font-medium {{ textColor() }}">
                {{ d.score }}
                <br />
                <span>CO</span>
                <sub>2</sub>
                <span>-Klasse</span>
            </span>
        </div>
    </ng-template>

    <ng-template #kwhPer100KmCombined let-value="value">
        <div class="flex w-max items-center gap-4">
            <i class="icon-envkv-battery text-24 {{ textColor() }}"></i>
            <span class="font-text text-10 font-medium {{ textColor() }}">
                {{ value }}&nbsp;kWh/100km
                <br />
                kombiniert
            </span>
        </div>
    </ng-template>

    <ng-template #gPerKmCombined let-value="value">
        <div class="flex w-max items-center gap-4">
            <i class="icon-envkv-carbon text-24 {{ textColor() }}"></i>
            <span class="font-text text-10 font-medium {{ textColor() }}">
                {{ value }}&nbsp;g/km
                <br />
                kombiniert
            </span>
        </div>
    </ng-template>

    <ng-template #lPer100kmSummedCombined let-value="value">
        <div class="flex w-max items-center gap-4">
            <i class="icon-envkv-gas-station text-24 {{ textColor() }}"></i>
            <span class="font-text text-10 font-medium {{ textColor() }}">
                {{ value }}&nbsp;l/100km
                <br />
                gewicht
                <br />
                kombiniert
            </span>
        </div>
    </ng-template>

    <ng-template #lPer100kmElectricCombined let-value="value">
        <div class="flex w-max items-center gap-4">
            <i class="icon-envkv-gas-station-battery text-24 {{ textColor() }}"></i>
            <span class="font-text text-10 font-medium {{ textColor() }}">
                {{ value }}&nbsp;l/100km bei
                <br />
                entl. Batterie
                <br />
                kombiniert
            </span>
        </div>
    </ng-template>

    <ng-template #kwhPer100kmCombined let-value="value">
        <div class="flex w-max items-center gap-4">
            <i class="icon-envkv-battery text-24 {{ textColor() }}"></i>
            <span class="font-text text-10 font-medium {{ textColor() }}">
                {{ value }}&nbsp;kWh/100km
                <br />
                gewichtet
                <br />
                kombiniert
            </span>
        </div>
    </ng-template>

    <ng-template #gPerKmSummedCombined let-value="value">
        <div class="flex w-max items-center gap-4">
            <i class="icon-envkv-carbon text-24 {{ textColor() }}"></i>
            <span class="font-text text-10 font-medium {{ textColor() }}">
                {{ value }}&nbsp;g/km
                <br />
                gewichtet
                <br />
                kombiniert
            </span>
        </div>
    </ng-template>

    <ng-template #co2ClassCombined>
        <div class="flex w-max items-center gap-4">
            <i class="icon-envkv-eco-plant text-24 {{ textColor() }}"></i>
            <span class="font-text text-10 font-medium {{ textColor() }}">
                <span>{{ d.score }}&nbsp;CO</span>
                <sub>2</sub>
                <span>-Emissionen</span>
                <br />
                <span>gewichtet</span>
                <br />
                <span>kombiniert</span>
            </span>
        </div>
    </ng-template>

    <ng-template #co2ClassElectricCombined let-value="value">
        <div class="flex w-max items-center gap-4">
            <i class="icon-envkv-eco-plant-battery text-24 {{ textColor() }}"></i>
            <span class="font-text text-10 font-medium {{ textColor() }}">
                <span>
                    <span>{{ value }}&nbsp;CO</span>
                    <sub>2</sub>
                    <span>-Emissionen</span>
                </span>
                <br />
                bei entl. Batterie
                <br />
                kombiniert
            </span>
        </div>
    </ng-template>

    <ng-template #lPer100kmCombined let-value="value">
        <div class="flex w-max items-center gap-4">
            <i class="icon-envkv-gas-station text-24 {{ textColor() }}"></i>
            <span class="font-text text-10 font-medium {{ textColor() }}">
                {{ value }}&nbsp;l/100km
                <br />
                kombiniert
            </span>
        </div>
    </ng-template>
}
