<div class="bg-grey-500 py-24 lg:pb-56">
    <div class="inner-page-wrapper">
        <div class="mb-24 block min-h-[30px] md:hidden">
            @if (envkv(); as data) {
                @if (data && data.envkv && data.model) {
                    <ui-m040-c010-envkv [data]="data" [color]="'black'" />
                }
            }
        </div>

        <div class="flex flex-col items-start justify-center gap-12 md:flex-row md:pt-24 lg:gap-[104px]">
            @if (data().length > 0) {
                <div class="flex flex-wrap gap-16 xs:gap-32">
                    @for (item of data(); track $index; let last = $last) {
                        <div>
                            @let label = item.label | footnotes;
                            <span class="text-16 leading-27 text-black" [innerHTML]="label()"></span>
                            <div class="mt-2 flex items-end gap-4 text-black">
                                <span class="font-headline text-31 font-medium leading-36 md:text-50 md:leading-58">
                                    {{ item.value }}
                                </span>
                                <span
                                    class="leading-12 mb-5 font-headline text-14 font-medium md:text-20 md:leading-30"
                                >
                                    {{ item.unit }}
                                </span>
                            </div>
                            @if (item.link && item.linkText) {
                                <a ui-m010-c020-icon-link class="mt-4 inline-block" [hyundaiRouterLink]="item.link">
                                    {{ item.linkText }}
                                </a>
                            }
                        </div>
                        @if (!last) {
                            <div class="my-8 h-auto w-1 bg-grey-300"></div>
                        }
                    }
                </div>
            }

            <div class="flex w-full flex-col gap-16 md:w-auto">
                @if (showOnlyPrimaryButton()) {
                    <a
                        class="button-primary"
                        [hyundaiRouterLink]="primaryButton()"
                        (click)="primaryButtonClicked.emit()"
                    >
                        {{ primaryButtonText() }}
                    </a>
                } @else {
                    <a
                        ui-m010-c010-icon-double-cta
                        class="block w-full md:w-auto"
                        [hyundaiRouterLink]="ctaLink()"
                        (click)="ctaClicked.emit()"
                    >
                        {{ ctaText() || '' }}
                        <ng-container subtext>{{ ctaSubText() || '' }}</ng-container>
                    </a>
                    <a
                        ui-m010-c020-icon-link
                        class="mx-auto inline-block lg:mx-0"
                        [hyundaiRouterLink]="link()"
                        (click)="linkClicked.emit()"
                    >
                        {{ linkText() }}
                    </a>
                }
            </div>
        </div>
        <div class="mt-40 hidden justify-center md:flex">
            @if (envkv(); as data) {
                @if (data && data.envkv && data.model) {
                    <ui-m040-c010-envkv [data]="data" [color]="'black'" />
                }
            }
        </div>
    </div>
</div>
