<div class="w-full bg-grey-100 md:bg-white">
    <div class="inner-page-wrapper flex w-full flex-col items-center justify-between gap-24 py-40 md:flex-row">
        <div class="flex w-full flex-1 flex-col gap-12 text-black">
            <div class="text-34 leading-41">
                <ng-content select="[headline]" />
            </div>
            <div class="font-headline text-18 leading-27">
                <ng-content select="[subheadline]" />
            </div>
        </div>
        <div class="w-full md:flex md:w-max md:justify-end">
            @if (showOnlyPrimaryButton()) {
                <a
                    class="button-primary w-auto"
                    [hyundaiRouterLink]="primaryButton()"
                    (click)="primaryButtonClicked.emit()"
                >
                    {{ primaryButtonText() }}
                </a>
            } @else {
                <div class="flex flex-col gap-16">
                    <a
                        ui-m010-c010-icon-double-cta
                        class="block w-full md:w-auto"
                        [hyundaiRouterLink]="ctaLink()"
                        (click)="ctaClicked.emit()"
                    >
                        {{ ctaText() || '' }}
                        <ng-container subtext>{{ ctaSubText() || '' }}</ng-container>
                    </a>
                    <a
                        ui-m010-c020-icon-link
                        class="inline-block"
                        [hyundaiRouterLink]="link()"
                        (click)="linkClicked.emit()"
                    >
                        {{ linkText() }}
                    </a>
                </div>
            }
        </div>
    </div>
</div>
