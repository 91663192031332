import type { ElementRef } from '@angular/core';
import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    computed,
    effect,
    inject,
    input,
    viewChildren,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { M040C070ContactPersonComponent } from '../../040-content-structure/m040-c070-contact-person/m040-c070-contact-person.component';
import type { M030C050TeamAccordeonData } from './m030-c050-team-accordeon.interface';

@Component({
    selector: 'ui-m030-c050-team-accordeon',
    standalone: true,
    templateUrl: './m030-c050-team-accordeon.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [M040C070ContactPersonComponent],
})
export class M030C050TeamAccordeonComponent {
    route = inject(ActivatedRoute);

    teams = input.required<M030C050TeamAccordeonData[]>();

    queryParams = toSignal(this.route.queryParams);
    initialOpenSlug = computed(() => this.queryParams()?.['team'] as string | undefined);

    teamsElement = viewChildren<ElementRef>('team');
    teamsNative = computed(() => this.teamsElement().map((team) => team.nativeElement as HTMLDivElement));

    initialOpenAccordeon = effect(() => {
        const teamsNative = this.teamsNative();
        if (teamsNative.length === 0) return;

        const open = this.initialOpenSlug();

        if (open) {
            const team = teamsNative.find((t) => t.id === open);
            if (team) {
                /* eslint-disable @rx-angular/prefer-no-layout-sensitive-apis */
                team.scrollIntoView();
            }
        }
        this.initialOpenAccordeon.destroy();
    });
}
