<div class="inner-page-wrapper flex flex-col gap-24 py-40">
    <div class="md:mx-auto md:max-w-[1004px]">
        <div
            class="leading-29 font-headline text-24 font-medium text-black empty:hidden md:text-center md:text-34 md:leading-41"
        >
            <ng-content select="[headline]" />
        </div>
        <div class="mt-12 text-16 leading-27 text-black empty:hidden md:text-center">
            <ng-content select="[text]" />
        </div>
    </div>
    <div
        class="mx-auto grid grid-cols-2 gap-16 md:mb-[26px] md:mt-40 md:max-w-[1004px] md:gap-56"
        [ngClass]="'lg:grid-cols-' + facts().length"
    >
        @for (item of facts(); track $index) {
            <div class="flex flex-col items-center">
                <div class="flex items-end md:justify-center">
                    <span class="font-headline text-50 font-medium leading-58 text-black md:text-74 md:leading-78">
                        {{ item.value }}
                    </span>
                    <span
                        class="mb-6 ml-4 font-headline text-18 leading-27 text-black md:mb-8 md:text-20 md:leading-30"
                    >
                        {{ item.unit }}
                    </span>
                </div>
                @let label = item.label | footnotes;
                <span
                    class="leading-21 text-center text-14 text-black md:mt-12 md:text-16 md:leading-27"
                    [innerHTML]="label()"
                ></span>
            </div>
        }
    </div>
    <div class="flex flex-col gap-24 md:mx-auto md:grid md:grid-cols-2 md:gap-40">
        @if (showOnlyPrimaryButton()) {
            <a
                class="button-primary col-span-2"
                [hyundaiRouterLink]="primaryButton()"
                (click)="primaryButtonClicked.emit()"
            >
                {{ primaryButtonText() }}
            </a>
        } @else {
            <a
                ui-m010-c010-icon-double-cta
                class="md:order-last"
                [hyundaiRouterLink]="ctaLink()"
                (click)="ctaClicked.emit()"
            >
                {{ ctaText() }}
                <ng-container subtext>{{ ctaSubText() }}</ng-container>
            </a>
            <a class="button-secondary h-full md:py-[28px]" [hyundaiRouterLink]="link()" (click)="linkClicked.emit()">
                {{ linkText() }}
            </a>
        }
    </div>
    @if (externalLink() && externalLinkText()) {
        <a ui-m010-c025-icon-link-external class="mx-auto" [hyundaiRouterLink]="externalLink()">
            {{ externalLinkText() }}
        </a>
    }
</div>
