import {
    ChangeDetectionStrategy,
    Component,
    SecurityContext,
    ViewEncapsulation,
    computed,
    inject,
    input,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'ui-m040-c020-rte',
    standalone: true,
    imports: [],
    templateUrl: './m040-c020-rte.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M040C020RteComponent {
    sanitizer = inject(DomSanitizer);
    data = input.required<string>();

    safeData = computed(() => {
        const data = this.data();
        return this.sanitizer.sanitize(SecurityContext.HTML, data);
    });
}
