<div class="inner-page-wrapper pt-24 md:py-40">
    @for (department of teams(); track department) {
        <details class="group" #team [id]="department.slug" [open]="department.slug === initialOpenSlug()">
            <summary
                class="flex w-full cursor-pointer items-center justify-between border-b-2 border-grey-200 py-24 pl-24 pr-16"
            >
                <span
                    class="font-headline text-18 font-medium leading-27 text-black md:text-22 md:leading-28 md:text-accent"
                >
                    {{ department.label }}
                </span>
                <i class="icon-chevron-down text-16 text-accent transition-all group-open:rotate-180"></i>
            </summary>

            <div
                class="grid grid-cols-1 gap-24 border-b-2 border-grey-200 px-24 py-16 group-open:animate-show md:grid-cols-2 md:p-40 lg:grid-cols-3"
            >
                @defer {
                    @for (contact of department.contacts; track contact) {
                        <ui-m040-c070-contact-person [data]="contact" [isFirstView]="false" />
                    }
                }
            </div>
        </details>
    }
</div>
