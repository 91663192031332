@if (logos(); as logos) {
    <div
        class="inner-page-wrapper flex flex-wrap justify-center gap-x-56 gap-y-24 border-b-1 border-grey-100 py-24 md:mb-8 md:justify-start md:pt-40"
    >
        @for (item of logos; track item) {
            @if (item) {
                <ui-m000-c010-image
                    class="block"
                    [classList]="'aspect-[260/60]'"
                    [image]="{
                        image: item.url,
                        desktop: { width: 260, height: 60 },
                        tablet: { width: 260, height: 60 },
                        mobile: { width: 260, height: 60 },
                    }"
                    [priority]="false"
                    [alt]="item.alt || 'Logo'"
                    contain
                />
            }
        }
    </div>
}
