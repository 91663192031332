import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    booleanAttribute,
    computed,
    input,
} from '@angular/core';
import type { Icon } from '@hyundai/assets';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[ui-m010-c010-icon-double-cta], a[ui-m010-c010-icon-double-cta]',
    exportAs: 'buttonUiIconDoubleCta',
    standalone: true,
    imports: [NgClass, RouterLinkDirective, NgTemplateOutlet],
    templateUrl: './m010-c010-icon-double-cta.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'classes()',
    },
})
export class M010C010IconDoubleCtaComponent {
    icon = input<Icon>();
    inverted = input(false, { transform: booleanAttribute });

    classes = computed(() => {
        return `flex justify-center group h-[80px] items-center px-20   py-14 ${this.inverted() ? 'bg-white' : 'bg-accent hover:bg-accent-cta-hover'}`;
    });
}
