import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import { M000C010ImageComponent } from '../../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M040C030BadgeComponent } from '../../../040-content-structure/m040-c030-badge/m040-c030-badge.component';
import type { M060C010VehicleShowroom } from '../m060-c010-vehicle-showroom.interface';

@Component({
    selector: 'ui-m060-c010-vehicle-showroom-item',
    standalone: true,
    templateUrl: './m060-c010-vehicle-showroom-item.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [M000C010ImageComponent, M040C030BadgeComponent],
})
export class M060C010VehicleShowroomItemComponent {
    car = input.required<M060C010VehicleShowroom>();

    isFirstView = input.required({ transform: booleanAttribute });
}
