import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input, output } from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { FootnotesPipe, RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M040C010EnvkvComponent } from '../../040-content-structure/m040-c010-envkv/m040-c010-envkv.component';

@Component({
    selector: 'ui-m060-c020-vehicle-leasing-example',
    standalone: true,
    templateUrl: './m060-c020-vehicle-leasing-example.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLinkDirective, M000C010ImageComponent, NgClass, FootnotesPipe, M040C010EnvkvComponent],
})
export class M060C020VehicleLeasingExampleComponent {
    headline = input.required<string>();
    image = input.required<{
        url: string;
        alt?: string | null;
    }>();
    imageCaption = input<string | null>();
    nationalPricesDisclaimer = input<string | null>();
    text = input.required<string>();
    removeMobileImage = input(false, { transform: booleanAttribute });

    tableHeadline = input<string>();
    tableHeadlineValue = input<string>();
    tableData = input<Array<{ label: string; value: string }>>();

    link = input.required<string>();
    linkText = input.required<string>();
    linkClicked = output();

    envkv = input<{ model: string; subheadline?: string; envkv: ApiTypes.Envkv; score: string }>();

    isFirstView = input.required({ transform: booleanAttribute });
}
