import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'a[ui-m040-c040-promotion-banner], button[ui-m040-c040-promotion-banner]',
    exportAs: 'promotionBanner',
    standalone: true,
    imports: [NgClass, NgTemplateOutlet],
    templateUrl: './m040-c040-promotion-banner.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'bg-blue block hover:cursor-pointer',
    },
})
export class M040C040PromotionBannerComponent {}
