<div class="bg-white md:bg-accent">
    <div class="block px-24 py-8 md:hidden">
        <div class="h-1 w-full bg-grey-300"></div>
    </div>
    <div class="inner-page-wrapper flex flex-col items-center justify-between gap-24 pb-24 pt-16 md:flex-row md:pt-24">
        <div class="flex flex-wrap gap-16 xs:gap-32">
            @if (data(); as d) {
                @for (item of d; track $index; let last = $last) {
                    <div>
                        @let label = item.label | footnotes;
                        <span class="text-16 leading-27 text-accent md:text-white" [innerHTML]="label()"></span>
                        <div class="mt-2 flex items-end gap-4 text-accent md:text-white">
                            <span class="font-headline text-31 font-medium leading-36 md:text-50 md:leading-58">
                                {{ item.value }}
                            </span>
                            <span class="leading-12 mb-5 font-headline text-14 font-medium md:text-20 md:leading-30">
                                {{ item.unit }}
                            </span>
                        </div>
                        @if (item.link && item.linkText) {
                            <a
                                ui-m010-c020-icon-link
                                class="mt-4 hidden md:inline-flex"
                                [hyundaiRouterLink]="item.link"
                                inverted
                            >
                                {{ item.linkText }}
                            </a>
                            <a
                                ui-m010-c020-icon-link
                                class="mt-4 inline-block md:hidden"
                                [hyundaiRouterLink]="item.link"
                            >
                                {{ item.linkText }}
                            </a>
                        }
                    </div>
                    @if (!last) {
                        <div class="my-8 h-auto w-1 bg-grey-300 md:bg-white"></div>
                    }
                }
            }
        </div>

        <!-- Desktop -->
        <div class="hidden w-auto flex-col gap-16 md:flex">
            @if (showOnlyPrimaryButton()) {
                <a
                    class="button-primary w-auto bg-white text-accent hover:bg-white"
                    [hyundaiRouterLink]="primaryButton()"
                    (click)="primaryButtonClicked.emit()"
                >
                    {{ primaryButtonText() }}
                </a>
            } @else {
                <a
                    ui-m010-c010-icon-double-cta
                    class="block w-auto"
                    [hyundaiRouterLink]="ctaLink()"
                    (click)="ctaClicked.emit()"
                    inverted
                >
                    {{ ctaText() || '' }}
                    <ng-container subtext>{{ ctaSubText() || '' }}</ng-container>
                </a>
                <a
                    ui-m010-c020-icon-link
                    class="inline-block"
                    [hyundaiRouterLink]="link()"
                    (click)="linkClicked.emit()"
                    inverted
                >
                    {{ linkText() }}
                </a>
            }
        </div>

        <!-- Mobile -->
        <div class="flex w-full flex-col gap-16 md:hidden">
            @if (showOnlyPrimaryButton()) {
                <a class="button-primary" [hyundaiRouterLink]="primaryButton()" (click)="primaryButtonClicked.emit()">
                    {{ primaryButtonText() }}
                </a>
            } @else {
                <a
                    ui-m010-c010-icon-double-cta
                    class="block w-full"
                    [hyundaiRouterLink]="ctaLink()"
                    (click)="ctaClicked.emit()"
                >
                    {{ ctaText() || '' }}
                    <ng-container subtext>{{ ctaSubText() || '' }}</ng-container>
                </a>
                <a
                    ui-m010-c020-icon-link
                    class="inline-block"
                    [hyundaiRouterLink]="link()"
                    (click)="linkClicked.emit()"
                >
                    {{ linkText() }}
                </a>
            }
        </div>
    </div>
</div>
