import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import { RouterLinkDirective, TrackingDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';

@Component({
    selector: 'ui-m040-c060-card-small',
    standalone: true,
    templateUrl: './m040-c060-card-small.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLinkDirective, M000C010ImageComponent, TrackingDirective],
})
export class M040C060CardSmallComponent {
    headline = input.required<string>();
    link = input.required<string>();
    image = input.required<{ url: string; alt?: string | null }>();

    isFirstView = input.required({ transform: booleanAttribute });
    trackingEnabled = input(true, { transform: booleanAttribute });
}
