<div class="relative mx-auto max-w-[1920px]">
    <ui-m000-c010-image
        class="image-hover block h-full"
        [image]="{
            image: image().url,
            desktop: { width: 1920, height: 600 },
            tablet: { width: 1022, height: 425 },
            mobile: { width: 767, height: 649 },
        }"
        [priority]="isFirstView()"
        [alt]="image().alt || title()"
        [focusAi]="ImageAiFocus.VEHICLE"
        classList="h-full w-full aspect-[767/649] md:aspect-[1022/425] lg:aspect-[1920/600] object-cover"
        cover
        highRes
    />
    <div class="absolute bottom-0 left-0 h-[250px] w-full bg-gradient-to-t from-black-70"></div>

    <div class="inner-page-wrapper absolute inset-0 flex w-full flex-col justify-between py-24 lg:pb-[50px] lg:pt-64">
        <div class="flex justify-between">
            <div class="flex flex-col gap-10 md:flex-row">
                <ui-m040-c030-badge type="blue">
                    {{ isAvailable() ? 'Sofort verfügbar' : 'Bei uns bestellbar' }}
                </ui-m040-c030-badge>

                @if (isDigital()) {
                    <a [hyundaiRouterLink]="'.'" fragment="digital-erleben">
                        <ui-m040-c030-badge type="white" icon="icon-smartphone-car">Digital erleben</ui-m040-c030-badge>
                    </a>
                }
            </div>

            @if (logoImages(); as images) {
                <div class="flex flex-col gap-16">
                    @for (item of images; track item.url) {
                        <ui-m000-c010-image
                            class="block h-full"
                            [image]="{
                                image: item.url,
                                desktop: { width: 290, height: 88 },
                                tablet: { width: 290, height: 88 },
                                mobile: { width: 145, height: 44 },
                            }"
                            [priority]="isFirstView()"
                            [alt]="item.alt || 'Logo'"
                            classList="w-[145px] md:w-[290px]"
                            ignoreHeight
                            cover
                        />
                    }
                </div>
            }
        </div>
        <div class="flex flex-col justify-between gap-24 md:flex-row md:items-end">
            <div class="max-w-[1000px]">
                <h1 class="text-31 leading-36 text-white lg:text-74 lg:leading-78">
                    {{ title() }}
                </h1>
                <p class="font-headline text-16 font-medium leading-28 text-white lg:text-22">{{ text() }}</p>
            </div>
            <div class="flex w-full flex-col items-end md:w-auto">
                <ng-content select="[extras]" />

                <div class="mt-12 hidden lg:block">
                    <ng-container [ngTemplateOutlet]="envkvTemplate" [ngTemplateOutletContext]="{ color: 'white' }" />
                </div>
            </div>
        </div>
    </div>
</div>
@if (envkv()?.envkv) {
    <div class="inner-page-wrapper mx-auto flex justify-center py-14 md:pb-0 lg:hidden">
        <ng-container [ngTemplateOutlet]="envkvTemplate" [ngTemplateOutletContext]="{ color: 'black' }" />
    </div>
}

<ng-template #envkvTemplate let-color="color">
    @if (envkv(); as data) {
        @if (data && data.envkv && data.model) {
            <div class="w-max">
                <ui-m040-c010-envkv [data]="data" [color]="color" />
            </div>
        }
    }
</ng-template>
