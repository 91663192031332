import { isPlatformServer } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    PLATFORM_ID,
    ViewEncapsulation,
    booleanAttribute,
    computed,
    effect,
    inject,
    input,
    untracked,
    viewChild,
} from '@angular/core';
import { outputFromObservable, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import {
    NgxPxCarouselAwesomePlusComponent,
    NgxPxCarouselAwesomePlusItemDirective,
    NgxPxCarouselAwesomePlusPlaceholderComponent,
} from '@pixelgenau/ngx-px-carousel-awesome-plus';
import { M000C010ImageComponent } from '../../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M040C030BadgeComponent } from '../../../040-content-structure/m040-c030-badge/m040-c030-badge.component';
import { M060C010VehicleShowroomItemComponent } from '../m060-c010-vehicle-showroom-item/m060-c010-vehicle-showroom-item.component';
import type { M060C010VehicleShowroom } from '../m060-c010-vehicle-showroom.interface';

@Component({
    selector: 'ui-m060-c010-vehicle-showroom-slider',
    standalone: true,
    templateUrl: './m060-c010-vehicle-showroom-slider.component.html',
    styleUrls: ['./m060-c010-vehicle-showroom-slider.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgxPxCarouselAwesomePlusComponent,
        NgxPxCarouselAwesomePlusItemDirective,
        NgxPxCarouselAwesomePlusPlaceholderComponent,
        M000C010ImageComponent,
        M040C030BadgeComponent,
        M060C010VehicleShowroomSliderComponent,
        M060C010VehicleShowroomItemComponent,
    ],
})
export class M060C010VehicleShowroomSliderComponent {
    activatedRoute = inject(ActivatedRoute);
    router = inject(Router);
    platformId = inject(PLATFORM_ID);

    activeParams = toSignal(this.activatedRoute.queryParams);

    isFirstView = input.required({ transform: booleanAttribute });
    cars = input.required<M060C010VehicleShowroom[]>();

    currentIndex = computed(() => this.carousel().currentIndex() ?? 0);

    carousel = viewChild.required(NgxPxCarouselAwesomePlusComponent);

    emitCurrentTabIndex = outputFromObservable(toObservable(this.currentIndex));

    slideToIndexEffect = effect(() => {
        if (isPlatformServer(this.platformId)) return;

        const carParam = this.activeParams()?.['car'] as string;
        const carousel = this.carousel();
        if (!carParam || !carousel) return;

        untracked(() => {
            const initIndex = this.cars().findIndex(
                (car) => car.label.toLocaleUpperCase() === carParam.toLocaleUpperCase(),
            );
            this.slideToIndex(initIndex);

            this.slideToIndexEffect.destroy();
        });
    });

    slideToIndex(index: number): void {
        this.carousel().goToIndicatorIndex(index + 1);
    }
}
