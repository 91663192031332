import { booleanAttribute, ChangeDetectionStrategy, Component, inject, input, ViewEncapsulation } from '@angular/core';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { DialogService } from '@pixelgenau/ngx-px-dialog';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import { ImageAiFocus } from '../../000-primitives/m000-c010-image/m000-c010-image.interface';
import { M010C010IconDoubleCtaComponent } from '../../010-buttons-and-links/m010-c010-icon-double-cta/m010-c010-icon-double-cta.component';
import { M010C025IconLinkExternalComponent } from '../../010-buttons-and-links/m010-c025-icon-link-external/m010-c025-icon-link-external.component';
import { ChildM030C90GalleryOverlayDialogTag } from './child-m030-c090-gallery-overlay/child-m030-c090-gallery-overlay.dialog';
import type { M030C090GalleryImage } from './m030-c090-gallery.interface';

@Component({
    selector: 'ui-m030-c090-gallery',
    standalone: true,
    templateUrl: './m030-c090-gallery.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLinkDirective,
        M000C010ImageComponent,
        M010C025IconLinkExternalComponent,
        M010C010IconDoubleCtaComponent,
    ],
})
export class M030C90GalleryComponent {
    images = input.required<M030C090GalleryImage[]>();
    caption = input<string>();
    isFirstView = input.required({ transform: booleanAttribute });
    protected readonly dialogService = inject(DialogService);

    ImageAiFocus = ImageAiFocus;

    async openGallery(image: string): Promise<void> {
        this.dialogService
            .openDialog(ChildM030C90GalleryOverlayDialogTag, {
                image,
            })
            .subscribe();
    }
}
