import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import type { Icon } from '@hyundai/assets';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';

@Component({
    selector: 'ui-m030-c040-default-teaser',
    standalone: true,
    templateUrl: './m030-c040-default-teaser.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLinkDirective, M000C010ImageComponent, NgClass],
})
export class M030C040DefaultTeaserComponent {
    image = input.required<string>();
    imageAlt = input<string>();
    imageLeft = input(true, { transform: booleanAttribute });
    imageContains = input(false, { transform: booleanAttribute });

    list = input<Array<{ icon: Icon; text: string }>>();

    isFirstView = input.required({ transform: booleanAttribute });
}
