import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, input } from '@angular/core';

@Component({
    selector: 'ui-m040-c030-badge',
    standalone: true,
    imports: [NgClass],
    templateUrl: './m040-c030-badge.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M040C030BadgeComponent {
    type = input<'accent' | 'blue' | 'white'>('accent');
    icon = input<string>();
}
