import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    booleanAttribute,
    computed,
    input,
} from '@angular/core';
import type { Icon } from '@hyundai/assets';
import { FootnotesPipe, RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M010C010IconDoubleCtaComponent } from '../../010-buttons-and-links/m010-c010-icon-double-cta/m010-c010-icon-double-cta.component';
import { M010C020IconLinkComponent } from '../../010-buttons-and-links/m010-c020-icon-link/m010-c020-icon-link.component';

@Component({
    selector: 'ui-m030-c020-teaser-allrounder',
    standalone: true,
    templateUrl: './m030-c020-teaser-allrounder.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLinkDirective,
        NgClass,
        FootnotesPipe,
        M010C020IconLinkComponent,
        M010C010IconDoubleCtaComponent,
        M000C010ImageComponent,
    ],
})
export class M040C020TeaserAllrounderComponent {
    strongText = input<string>();
    list = input<Array<{ icon: Icon; text: string }>>();
    image = input.required<string>();
    imageAlt = input<string>('Default Teaser Bild');
    isFirstView = input.required({ transform: booleanAttribute });

    heroImage = computed(() => ({
        image: this.image(),
        desktop: { width: 1000, height: 600 },
        tablet: { width: 1000, height: 560 },
        mobile: { width: 766, height: 560 },
    }));
}
