<i
    class="text-48"
    [ngClass]="[
        icon() ?? 'icon-phone',
        !icon() ? 'group-hover:animate-wiggle' : '',
        inverted() ? 'text-accent' : 'text-white',
    ]"
></i>
<span class="ml-12 flex flex-col items-start gap-3">
    <span class="font-text text-14 leading-20 font-medium text-nowrap {{ inverted() ? 'text-accent' : 'text-white' }}">
        <ng-content />
    </span>
    <span
        class="font-headline text-24 leading-28 font-medium text-nowrap {{ inverted() ? 'text-accent' : 'text-white' }}"
    >
        <ng-content select="[subtext]" />
    </span>
</span>
