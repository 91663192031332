<div class="inner-page-wrapper-px-0">
    <div class="flex flex-wrap md:flex-nowrap md:gap-2">
        @if (images().length === 1) {
            @for (item of images(); track $index) {
                <div class="group relative cursor-pointer overflow-hidden" (click)="openGallery(item.url)">
                    <ui-m000-c010-image
                        class="image-hover block h-full"
                        [classList]="
                            'h-full w-full aspect-[767/202] md:aspect-[975/257] lg:aspect-[1216/321] object-cover'
                        "
                        [image]="{
                            image: item.url,
                            desktop: { width: 1216, height: 321 },
                            tablet: { width: 975, height: 257 },
                            mobile: { width: 767, height: 202 },
                        }"
                        [priority]="isFirstView()"
                        [alt]="item.alt || 'Bild ' + $index"
                        [focusAi]="ImageAiFocus.VEHICLE"
                    />
                    <i class="icon-zoom absolute bottom-16 right-16 text-24 text-white"></i>
                </div>
            }
        } @else if (images().length === 2) {
            @for (item of images(); track $index) {
                <div class="group relative cursor-pointer overflow-hidden" (click)="openGallery(item.url)">
                    <ui-m000-c010-image
                        class="image-hover block h-full"
                        [classList]="
                            'h-full w-full aspect-[767/432] md:aspect-[486/321] lg:aspect-[607/321] object-cover'
                        "
                        [image]="{
                            image: item.url,
                            desktop: { width: 607, height: 321 },
                            tablet: { width: 486, height: 323 },
                            mobile: { width: 767, height: 432 },
                        }"
                        [priority]="isFirstView()"
                        [alt]="item.alt || 'Bild ' + $index"
                        [focusAi]="ImageAiFocus.VEHICLE"
                    />
                    <i class="icon-zoom absolute bottom-16 right-16 text-24 text-white"></i>
                </div>
            }
        } @else if (images().length === 3) {
            @for (item of images(); track $index; let first = $first) {
                @if (first) {
                    <div
                        class="group relative cursor-pointer overflow-hidden md:max-w-1/3 lg:max-w-[45%]"
                        (click)="openGallery(item.url)"
                    >
                        <ui-m000-c010-image
                            class="image-hover block h-full"
                            [classList]="
                                'h-full w-full aspect-[767/432] md:aspect-square lg:aspect-[571/321] object-cover'
                            "
                            [image]="{
                                image: item.url,
                                desktop: { width: 800, height: 321 },
                                tablet: { width: 323, height: 323 },
                                mobile: { width: 767, height: 432 },
                            }"
                            [priority]="isFirstView()"
                            [alt]="item.alt || 'Bild ' + $index"
                            [focusAi]="ImageAiFocus.VEHICLE"
                            highRes
                        />
                        <i class="icon-zoom absolute bottom-16 right-16 text-24 text-white"></i>
                    </div>
                } @else {
                    <div
                        class="group relative max-w-1/2 cursor-pointer overflow-hidden md:w-auto"
                        (click)="openGallery(item.url)"
                    >
                        <ui-m000-c010-image
                            class="image-hover block h-full"
                            [classList]="'h-full w-full aspect-square object-cover'"
                            [image]="{
                                image: item.url,
                                desktop: { width: 321, height: 321 },
                                tablet: { width: 321, height: 321 },
                                mobile: { width: 382, height: 382 },
                            }"
                            [priority]="isFirstView()"
                            [alt]="item.alt || 'Bild ' + $index"
                            [focusAi]="ImageAiFocus.VEHICLE"
                        />
                        <i class="icon-zoom absolute bottom-16 right-16 text-24 text-white"></i>
                    </div>
                }
            }
        }
    </div>
    @if (caption(); as cap) {
        <p class="leading-21 mt-12 px-24 text-14 text-grey-400 md:px-0">
            {{ cap }}
        </p>
    }
</div>
