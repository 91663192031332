import { ChangeDetectionStrategy, Component, ViewEncapsulation, input } from '@angular/core';

@Component({
    selector: 'ui-m060-c010-vehicle-showroom-header',
    standalone: true,
    templateUrl: './m060-c010-vehicle-showroom-header.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M060C010VehicleShowroomHeaderComponent {
    headline = input.required<string>();
    subheadline = input.required<string>();
}
