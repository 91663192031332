<div
    class="inline-flex px-12 py-2 font-headline text-12 md:py-0 md:text-16 md:font-medium md:leading-27"
    [ngClass]="{
        'text-white': type() === 'blue' || type() === 'accent',
        'text-black': type() === 'white',
        'bg-blue': type() === 'blue',
        'bg-white': type() === 'white',
        'bg-accent font-medium': type() === 'accent',
    }"
>
    @if (icon()) {
        <i class="text-16 md:text-24" [ngClass]="[icon()]"></i>
    }
    <ng-content />
</div>
