import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M040C070ContactPersonComponent } from '../m040-c070-contact-person/m040-c070-contact-person.component';
import type { M040C070ContactPersonData } from '../m040-c070-contact-person/m040-c070-contact-person.interface';

@Component({
    selector: 'ui-m040-c130-contact-banner',
    standalone: true,
    templateUrl: './m040-c130-contact-banner.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLinkDirective, NgClass, M000C010ImageComponent, M040C070ContactPersonComponent],
})
export class M040C130ContactBannerComponent {
    contact = input.required<M040C070ContactPersonData>();

    cta = input<string>();
    ctaLink = input<string>();
    isFirstView = input.required({ transform: booleanAttribute });
}
