import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, booleanAttribute, inject, input, output } from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { Store } from '@ngrx/store';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';

@Component({
    standalone: true,
    imports: [NgClass, RouterLinkDirective, M000C010ImageComponent],
    templateUrl: './m040-c110-model-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-m040-c110-model-select',
})
export class M040C110ModelSelectComponent {
    store = inject(Store);

    categories = input.required<string[]>();
    activeCategory = input<string>();
    filteredModels = input<ApiTypes.ModelList['models']>();
    isFirstView = input.required({ transform: booleanAttribute });

    categorySelected = output<string>();
}
