import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation, input } from '@angular/core';

@Component({
    selector: 'ui-m060-c010-vehicle-showroom-tabs',
    standalone: true,
    templateUrl: './m060-c010-vehicle-showroom-tabs.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass],
})
export class M060C010VehicleShowroomTabsComponent {
    tabs = input<string[]>();
    currentIndex = input.required<number>();

    @Output() tabClicked: EventEmitter<number> = new EventEmitter();
}
