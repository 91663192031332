import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { EnvkvType } from '@hyundai/shared-lib';
import { DialogService } from '@pixelgenau/ngx-px-dialog';
import { M040C010EnvkvOverlayDialogTag } from './m040-c010-envkv-overlay/m040-c010-envkv-overlay.dialog';

@Component({
    selector: 'ui-m040-c010-envkv',
    standalone: true,
    imports: [NgTemplateOutlet],
    templateUrl: './m040-c010-envkv.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M040C010EnvkvComponent {
    protected readonly dialogService = inject(DialogService);

    EnvkvType = EnvkvType;
    data = input.required<{ model: string; subheadline?: string; envkv: ApiTypes.Envkv; score: string }>();
    color = input<'white' | 'black'>('black');

    textColor = computed(() => {
        const color = this.color();
        return `text-${color}`;
    });

    async openGallery(): Promise<void> {
        const data = this.data();
        if (!data || !data.envkv) {
            return;
        }

        let type = '';
        switch (data.envkv.__typename) {
            case EnvkvType.EnVkvElektro: {
                type = 'Elektro';
                break;
            }
            case EnvkvType.EnVkvPlugInHybrid: {
                type = 'Plug-in Hybrid';
                break;
            }
            case EnvkvType.EnVkvVerbrennerHybrid: {
                type = 'Verbrenner / Hybrid';
                break;
            }
        }
        this.dialogService
            .openDialog(M040C010EnvkvOverlayDialogTag, {
                model: `${data.model} ${type}`,
                description: data.subheadline,
                data: this.data(),
            })
            .subscribe();
    }
}
