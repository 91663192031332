import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import { FootnotesPipe, RouterLinkDirective, TrackingDirective } from '@hyundai/ng-common-lib';
import type { TrackingElementType } from '@hyundai/shared-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M040C030BadgeComponent } from '../m040-c030-badge/m040-c030-badge.component';

@Component({
    selector: 'ui-m040-c050-card',
    standalone: true,
    templateUrl: './m040-c050-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLinkDirective,
        M000C010ImageComponent,
        M040C030BadgeComponent,
        NgClass,
        FootnotesPipe,
        TrackingDirective,
    ],
})
export class M040C050CardComponent {
    headline = input.required<string>();
    link = input.required<string>();
    image = input.required<{
        url: string;
        alt?: string | null;
    }>();
    isFirstView = input.required({ transform: booleanAttribute });
    flushContent = input(false, { transform: booleanAttribute });
    badge = input<string>();
    text = input<string>();

    trackingElType = input<TrackingElementType>('teaser');
    trackingEnabled = input(true, { transform: booleanAttribute });
}
