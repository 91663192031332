import { ChangeDetectionStrategy, Component, computed, input, ViewEncapsulation } from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { FootnotesPipe, RouterLinkDirective } from '@hyundai/ng-common-lib';

@Component({
    selector: 'ui-m040-c005-imprint-notice',
    standalone: true,
    imports: [RouterLinkDirective, FootnotesPipe],
    templateUrl: './m040-c005-imprint-notice.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M040C005ImprintNoticeComponent {
    dealer = input.required<ApiTypes.Dealer>();

    label = computed(
        () => `Verantwortlich für den Inhalt dieser Seiten: ${this.dealer().name}.#f_dealer_legal_notice#`,
    );
}
