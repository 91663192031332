import { NgClass } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, input, output, ViewEncapsulation } from '@angular/core';
import { FootnotesPipe, RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M010C010IconDoubleCtaComponent } from '../../010-buttons-and-links/m010-c010-icon-double-cta/m010-c010-icon-double-cta.component';
import { M010C025IconLinkExternalComponent } from '../../010-buttons-and-links/m010-c025-icon-link-external/m010-c025-icon-link-external.component';
import type { M030C100BigShortFact } from './m030-c100-big-short-facts.interface';

@Component({
    selector: 'ui-m030-c100-big-short-facts',
    standalone: true,
    templateUrl: './m030-c100-big-short-facts.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLinkDirective,
        NgClass,
        M010C025IconLinkExternalComponent,
        M010C010IconDoubleCtaComponent,
        FootnotesPipe,
    ],
})
export class M030C100BigShortFactsComponent {
    facts = input.required<M030C100BigShortFact[]>();

    ctaLink = input.required<string>();
    ctaText = input.required<string>();
    ctaSubText = input.required<string>();

    link = input.required<string>();
    linkText = input.required<string>();

    externalLink = input<string | null>();
    externalLinkText = input<string>();

    primaryButton = input.required<string>();
    primaryButtonText = input.required<string>();
    showOnlyPrimaryButton = input(false, { transform: booleanAttribute });

    primaryButtonClicked = output();
    ctaClicked = output();
    linkClicked = output();
}
